import { IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

export type LanguageSelectorProps = {
    languageAbbreviation: string;
    onLanguageChange: (newLanguage: string) => void;
    showSelectedNativeName?: boolean;
}

const LanguageSelector = (props: LanguageSelectorProps) => {
    const { languageAbbreviation, onLanguageChange, showSelectedNativeName } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { i18n } = useTranslation();

    console.log(languageAbbreviation);

    const supportedLngs = useMemo(() => (i18n.options.supportedLngs as string[]).filter(l => l !== 'cimode'), [i18n.options.supportedLngs]);
    const isLanguageSupported = (lng: string) => supportedLngs.includes(lng);

    const getFlagAbbreviationForLanguageAbbreviation = (lng: string) => {
        if (lng === 'en') {
            return 'GB';
        }
        return lng.toUpperCase();
    }

    const getNativeNameForLanguageAbbreviation = (lng: string) => {
        switch (lng) {
            case 'en': return 'English';
            case 'de': return 'Deutsch';
            case 'hu': return 'Magyar';
            default: return lng;
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (newLanguage: string) => {
        if (newLanguage !== languageAbbreviation) {
            onLanguageChange(newLanguage);
        }
        handleClose();
    };

    return (
        <div>
            {isLanguageSupported(languageAbbreviation) && <IconButton
                edge="end"
                color="inherit"
                onClick={handleClick}
            >
                <ReactCountryFlag
                    countryCode={getFlagAbbreviationForLanguageAbbreviation(languageAbbreviation)}
                    svg
                />
                &nbsp;{showSelectedNativeName && <Typography>{getNativeNameForLanguageAbbreviation(languageAbbreviation)}</Typography>}
            </IconButton>}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {supportedLngs.map((lng) => (
                    <MenuItem key={lng} onClick={() => handleLanguageChange(lng)}>
                        <ReactCountryFlag countryCode={getFlagAbbreviationForLanguageAbbreviation(lng)} svg />
                        &nbsp; {getNativeNameForLanguageAbbreviation(lng)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default LanguageSelector;
